import React from 'react';
import classNames from 'classnames';
import { type LayoutName } from '@wix/communities-blog-client-common';
import { isTextDirectionRTL } from '../../../rich-content/services/text-direction';
import useDeviceType from '../../hooks/use-device-type';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import useLayoutColorClasses from '../../hooks/use-layout-color-classes';
import { getPostTitleFontSizeMobile } from '../../selectors/app-settings-selectors';
import { getFeedColorClassName } from '../../services/layout-config';
import DotDotDot from '../dotdotdot';
import Link from '../link/internal-link';
import { useSelector } from '../runtime-context';
import styles from './post-title.scss';

type PostTitleType = 'small' | 'medium' | 'large' | 'single' | 'search';

function isPostTitleType(type: string): type is PostTitleType {
  return ['small', 'medium', 'large', 'single', 'search'].includes(type);
}

type Props = {
  className?: string;
  fullRoute?: string | null;
  isInPostPage?: boolean;
  lineCount?: number;
  linkClassName?: string;
  showCategoryLabel?: boolean;
  style?: React.CSSProperties;
  title: string | undefined;
  to?: string;
  type: LayoutName | PostTitleType;
};

export const PostTitle: React.FC<Props> = ({
  style = {},
  className,
  isInPostPage = false,
  lineCount,
  linkClassName,
  title,
  to,
  type,
  showCategoryLabel = false,
  fullRoute,
}) => {
  const { isDesktop, isMobile } = useDeviceType();
  const postTitleFontSizeMobile = useSelector(getPostTitleFontSizeMobile);
  const { textColorClassName } = useLayoutColorClasses();
  const { applyFeedDesign, getPostClassName } = useIsFeedDesignEnabled();
  const Component = isInPostPage ? 'h1' : 'h2';
  const titleDirection =
    typeof title === 'string' && isInPostPage && isTextDirectionRTL(title)
      ? 'rtl'
      : undefined;
  const titleClassName = classNames(
    styles.title,
    styles[type],
    className,
    !applyFeedDesign && textColorClassName,
    'post-title',
    'blog-hover-container-element-color',
    type === 'single' && styles.singleNew,
    getPostClassName(
      getFeedColorClassName(
        isPostTitleType(type) ? undefined : type,
        'title-color',
      ),
      'title-font',
    ),
    isInPostPage && 'blog-post-page-title-font',
    showCategoryLabel && styles.withCategoryLabel,
  );
  const isInMobilePostPage = isInPostPage && isMobile;
  const titleStyle = isInMobilePostPage
    ? { ...style, fontSize: postTitleFontSizeMobile }
    : style;

  const fontClassName = isDesktop ? className : undefined;
  const ellipsisTitle = lineCount ? (
    <DotDotDot
      useExactLineHeight={true}
      clamp={lineCount}
      className={fontClassName}
    >
      {title}
    </DotDotDot>
  ) : (
    <span className={fontClassName}>{title}</span>
  );

  return (
    <Component
      className={titleClassName}
      style={titleStyle}
      data-hook="post-title"
      dir={titleDirection}
      tabIndex={-1}
      autoFocus={isInPostPage}
    >
      {to ? (
        <Link
          fullRoute={fullRoute}
          className={classNames('post-title__text', styles.link, linkClassName)}
          to={to}
        >
          {ellipsisTitle}
        </Link>
      ) : (
        <span className={classNames('post-title__text', fontClassName)}>
          {ellipsisTitle}
        </span>
      )}
    </Component>
  );
};

export default PostTitle;
