import type { ScrollingElement } from 'pro-gallery-lib';
import {
  FEED_PAGE_SECTIONS,
  isLayoutPGOneColumn,
  isLayoutPGSideBySide,
  isLayoutSlider,
  isLayoutTextOnImage,
  SECTION_MY_POSTS,
  SECTION_POST_LIST,
  SECTION_RELATED_POSTS,
  type Layout,
  type Section,
} from '@wix/communities-blog-client-common';
import type { LayoutImageRatio } from '../constants/pro-gallery-options';
import { getPostListCssVars } from './post-list-css-vars';
import Wix from './wix-sdk-polyfill';

type GetWrappersHorizontalMarginProps = {
  layoutPGSideBySide: boolean;
  layoutPGOneColumn: boolean;
  layoutMargins: number | undefined;
  useResponsiveStyling: boolean;
};

export const getWrappersHorizontalMargin = ({
  layoutPGSideBySide,
  layoutPGOneColumn,
  layoutMargins,
  useResponsiveStyling,
}: GetWrappersHorizontalMarginProps): number | 'auto' | undefined => {
  if (layoutPGSideBySide || layoutPGOneColumn) {
    return 'auto';
  }

  return useResponsiveStyling ? 0 : layoutMargins;
};

export const DEFAULT_MOBILE_WIDTH = 320;
export const DEFAULT_SLIDER_WIDTH = 369;
export const DEFAULT_DESKTOP_WIDTH = 980;

type IsUsingResponsiveStylingProps = {
  createdWithResponsiveEditor: boolean;
  section: Section;
};

export const isUsingResponsiveStyling = ({
  createdWithResponsiveEditor,
  section,
}: IsUsingResponsiveStylingProps) => {
  const isFeed = (FEED_PAGE_SECTIONS as readonly Section[]).includes(section);

  return createdWithResponsiveEditor && !isFeed;
};

type GetContainerWidthProps = {
  borderWidth: number;
  createdWithResponsiveEditor: boolean;
  hostWidth: number;
  isMobile: boolean;
  layoutMargins: number;
  layoutPostSize: number;
  layoutType?: Layout;
  rootWidth?: number;
  section: Section;
  useLayoutFixerExperimentEnabled: boolean;
  isEditor: boolean;
};

export const getContainerWidth = ({
  borderWidth,
  createdWithResponsiveEditor,
  hostWidth,
  isMobile,
  layoutMargins,
  layoutPostSize,
  layoutType,
  rootWidth,
  section,
  useLayoutFixerExperimentEnabled,
  isEditor,
}: GetContainerWidthProps): number => {
  if (
    isLayoutPGSideBySide(layoutType) ||
    isLayoutPGOneColumn(layoutType) ||
    section === SECTION_MY_POSTS
  ) {
    return layoutPostSize;
  }

  const width = hostWidth || rootWidth;

  if (isUndefinedWidth(width)) {
    if (isLayoutSlider(layoutType) && isEditor) {
      return DEFAULT_SLIDER_WIDTH;
    }
    if (useLayoutFixerExperimentEnabled) {
      return isMobile ? DEFAULT_MOBILE_WIDTH : DEFAULT_DESKTOP_WIDTH;
    }
    return 1;
  }
  const useResponsiveStyling = isUsingResponsiveStyling({
    createdWithResponsiveEditor,
    section,
  });

  if (isLayoutSlider(layoutType)) {
    return Math.max(1, width - layoutMargins * 2 - borderWidth * 2);
  } else if (useResponsiveStyling || layoutMargins < 1) {
    return width;
  } else {
    return Math.max(1, width - layoutMargins * 2);
  }
};

function isUndefinedWidth(width: unknown): width is undefined {
  return isNaN(width as number) || width === '';
}

type GetContainerProps = {
  borderWidth: number;
  createdWithResponsiveEditor: boolean;
  hostWidth: number;
  isMobile: boolean;
  layoutContentHeight: number;
  layoutImageRatio: LayoutImageRatio;
  layoutMargins?: number;
  layoutPostSize: number;
  layoutType?: Layout;
  rootWidth?: number;
  section: Section;
  useLayoutFixerExperimentEnabled: boolean;
  useMobilePostListLayout: boolean;
  isEditor: boolean;
};

export const getContainer = ({
  borderWidth,
  createdWithResponsiveEditor,
  hostWidth,
  isMobile,
  layoutContentHeight,
  layoutImageRatio,
  layoutMargins = 0,
  layoutPostSize,
  layoutType,
  rootWidth,
  section,
  useLayoutFixerExperimentEnabled,
  useMobilePostListLayout,
  isEditor,
}: GetContainerProps): {
  width: number;
  height?: number;
} => {
  const containerWidth = getContainerWidth({
    borderWidth,
    createdWithResponsiveEditor,
    hostWidth,
    isMobile,
    layoutMargins: isMobile && !useMobilePostListLayout ? 0 : layoutMargins,
    layoutPostSize,
    layoutType,
    rootWidth,
    section,
    useLayoutFixerExperimentEnabled,
    isEditor,
  });

  if (isLayoutSlider(layoutType)) {
    const useResponsiveStyling = isUsingResponsiveStyling({
      createdWithResponsiveEditor,
      section,
    });

    let rawWidth = containerWidth + borderWidth * 2;
    if (useResponsiveStyling) {
      rawWidth -= layoutMargins * 2;
    }

    return {
      width: containerWidth,
      height: rawWidth / (layoutImageRatio ?? 1) + layoutContentHeight,
    };
  }

  return {
    width: containerWidth,
    height: undefined,
  };
};

type GetWrapperStylesProps = {
  container: ReturnType<typeof getContainer>;
  createdWithResponsiveEditor: boolean;
  isMobile: boolean;
  layoutMargins?: number;
  layoutPostSize: number;
  layoutType?: Layout;
  section: Section;
};

export const getWrapperStyles = ({
  container,
  createdWithResponsiveEditor,
  isMobile,
  layoutMargins,
  layoutPostSize,
  layoutType,
  section,
}: GetWrapperStylesProps): React.CSSProperties => {
  const wrapperStyles: React.CSSProperties = {
    ...getPostListCssVars(),
    height: container.height,
  };

  const useResponsiveStyling = isUsingResponsiveStyling({
    createdWithResponsiveEditor,
    section,
  });

  const layoutPGSideBySide = isLayoutPGSideBySide(layoutType);
  const layoutPGOneColumn = isLayoutPGOneColumn(layoutType);

  if (
    (layoutPGSideBySide || layoutPGOneColumn) &&
    section !== SECTION_MY_POSTS
  ) {
    wrapperStyles.maxWidth = layoutPostSize;
  }

  if (
    (section !== SECTION_POST_LIST &&
      section !== SECTION_RELATED_POSTS &&
      section !== SECTION_MY_POSTS) ||
    layoutPGSideBySide
  ) {
    wrapperStyles.marginTop = useResponsiveStyling ? 20 : layoutMargins;
    wrapperStyles.marginBottom = useResponsiveStyling ? 0 : layoutMargins;

    const horizontalMargin = getWrappersHorizontalMargin({
      layoutPGSideBySide,
      layoutPGOneColumn,
      layoutMargins,
      useResponsiveStyling,
    });

    wrapperStyles.marginLeft = horizontalMargin;
    wrapperStyles.marginRight = horizontalMargin;
  }

  if (isMobile) {
    wrapperStyles.margin = 0;
  }

  return wrapperStyles;
};

export const getScrollingElement = (): ScrollingElement => {
  try {
    if (typeof window !== 'undefined') {
      return window;
    } else {
      // iFrame
      // return a "mock" of the window
      return {
        addEventListener: (eventName, callback) => {
          Wix.addEventListener(eventName.toUpperCase(), callback);
        },
        removeEventListener: (eventName, callback) => {
          Wix.removeEventListener(eventName.toUpperCase(), callback);
        },
      };
    }
  } catch (e) {
    console.error('Cannot get scrolling element', e);
    return null;
  }
};

export const getPGEmptyCoverImage = (layoutType: Layout | undefined) => ({
  height: 1,
  width: 10000,
  ...(isLayoutTextOnImage(layoutType) || isLayoutSlider(layoutType)
    ? ({ type: 'text' } as const)
    : {}),
});
