import React from 'react';
import classNames from 'classnames';
import {
  getTPASettignsLiveSiteEditingDeprecated,
  resolveId,
} from '@wix/communities-blog-client-common';
import { useSelector } from '../../../common/components/runtime-context';
import CreatePostButton from '../create-post-button';
import { type LayoutConfigListComponentProps } from './layout-config-component-props';
import styles from './post-list.scss';

const PostListSimple: React.FC<LayoutConfigListComponentProps> = (props) => {
  const { ItemComponent } = props;
  const isLiveSiteEditingDeprecated = useSelector(
    getTPASettignsLiveSiteEditingDeprecated,
  );
  const classNameForType = styles[props.type];

  return (
    <div className={classNameForType} data-hook="post-list">
      <div className={styles.largeHeader}>
        {props.showCreatePostAction && !isLiveSiteEditingDeprecated && (
          <CreatePostButton categorySlug={props.category?.slug} />
        )}
      </div>
      <div className={classNames(styles.list, classNameForType)}>
        {props.posts.map((post) => (
          <div
            key={resolveId(post)}
            data-id={resolveId(post)}
            className={styles.listItemContainer}
          >
            <div className={styles.listItem}>
              <ItemComponent
                post={post}
                type={props.type}
                onLikeClick={props.onLikeClick}
                itemConfig={props.itemConfig}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PostListSimple;
